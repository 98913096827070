import 'jquery-validation/dist/jquery.validate';
import 'jquery-validation/dist/additional-methods';
import 'datejs';

// Expects .validateForm on a form object
window.TS.initValidator = function(selector) {
  if (typeof selector === 'undefined') {
    selector = '.validateForm';
  }
  if ($(selector).length == 0) return;
  console.log('Initializing Form Validator for ' + selector);
  $(selector).validate();
};

window.TS.setUpValidator = function() {
  window.TS.setUpValidationDefaults();
  window.TS.addValidationMethods();
  window.TS.addCalenderValidationTrigger();
  window.TS.addSeparateDateValidationTrigger();
};

window.TS.setUpValidationDefaults = function() {
  $.validator.setDefaults({
    errorClass: 'state-error',
    validClass: 'state-success',
    errorElement: 'em',
    highlight: function(element, errorClass, validClass) {
      $(element)
        .closest('.field')
        .addClass(errorClass)
        .removeClass(validClass);
    },
    unhighlight: function(element, errorClass, validClass) {
      $(element)
        .closest('.field')
        .removeClass(errorClass)
        .addClass(validClass);
    },
    errorPlacement: function(error, element) {
      if (element.is(':radio') || element.is(':checkbox')) {
        element.closest('.option-group').after(error);
      } else {
        error.insertAfter(element.parent());
      }
    }
  });
};

window.TS.addValidationMethods = function() {
  $.validator.addMethod(
    'greaterThanInt',
    function(value, element, params) {
      if (params != null && $(params[0]) && $(params[0]).val()) {
        var otherValue = $(params[0]).val();
        if (otherValue.length === 0) {
          otherValue = 0;
        } else {
          otherValue = parseInt(otherValue, 10);
        }
        if (value !== '') {
          return parseInt(value, 10) >= otherValue;
        }
      } else {
        return true;
      }
      return (
        (isNaN(value) && isNaN($(params[0]).val())) ||
        isNaN($(params[0]).val()) ||
        $(params[0]).val() == '' ||
        parseInt(value, 10) < parseInt($(params[0]).val(), 10)
      );
    },
    'Cannot be less than {1}.'
  );

  $.validator.addMethod(
    'checkNonePresent',
    function(value, element, params) {
      if (value != null) {
        var thisValue = value.toString().split(',');
        if (thisValue.length > 1 && jQuery.inArray('None', thisValue) > -1) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    'Contradictory selection'
  );

  $.validator.addMethod(
    'checkNoneSpecificPresent',
    function(value, element, params) {
      if (value != null) {
        var thisValue = value.toString().split(',');
        if (thisValue.length > 1 && jQuery.inArray(params, thisValue) > -1) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    'Contradictory selection'
  );

  $.validator.addMethod(
    'dateFormat',
    function(value, element) {
      return this.optional(element) || Date.parseExact(value, 'dd-MMM-yyyy');
    },
    'Please enter a real date'
  );

  $.validator.addMethod(
    'pastDates',
    function(value, element) {
      var entered_date = Date.parseExact(value, 'dd-MMM-yyyy');
      if (entered_date === null || entered_date === undefined) return;
      return this.optional(element) || entered_date.isBefore();
    },
    'Please select a current or past date'
  );

  $.validator.addMethod(
    'pastYear',
    function(value, element) {
      var entered_date = Date.parseExact(value, 'yyyy');
      if (entered_date === null || entered_date === undefined) return;
      return this.optional(element) || entered_date.isBefore();
    },
    'Please select a current or past year'
  );

  $.validator.addMethod(
    'check_date_of_birth',
    function(value, element) {
      if ($('#form_dob_day').length === 0 || $('#form_dob_month').length === 0 || $('#form_dob_year').length === 0) {
        console.log(
          'Warning: The check_date_of_birth validation requires "#form_dob_day, #form_dob_month, #form_dob_year"'
        );
        return;
      }
      var day = $('#form_dob_day').val();
      var month = $('#form_dob_month').val();
      var year = $('#form_dob_year').val();
      var age = 18;
      var mydate = new Date();
      mydate.setFullYear(year, month - 1, day);
      var currdate = new Date();
      currdate.setFullYear(currdate.getFullYear() - age);
      return currdate > mydate;
    },
    'You must be at least 18 years of age.'
  );

  $.validator.addMethod(
    'requiredIfComplete',
    function(value, element, params) {
      if (params != null && $(params).val()) {
        if (value != null && value != '') {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    'Please complete all details.'
  );

  $.validator.addMethod(
    'greaterThan',
    function(value, element, params) {
      if (!/Invalid|NaN/.test(Date.parseExact(value, 'dd-MMM-yyyy'))) {
        return Date.parseExact(value, 'dd-MMM-yyyy') >= Date.parseExact($(params[0]).val(), 'dd-MMM-yyyy');
      }
      return (
        (isNaN(value) && isNaN($(params[0]).val())) ||
        Date.parseExact(value, 'dd-MMM-yyyy') >= Date.parseExact($(params[0]).val(), 'dd-MMM-yyyy')
      );
    },
    'Cannot be before {1}.'
  );

  $.validator.addMethod(
    'greaterThanTime',
    function(value, element, params) {
      if (!/Invalid|hh:mm|NaN/.test(Date.parseExact(value, 'H:mm'))) {
        return Date.parseExact(value, 'H:mm') >= Date.parseExact($(params[0]).val(), 'H:mm');
      }
      return (
        (isNaN(value) && isNaN($(params[0]).val())) ||
        Date.parseExact(value, 'H:mm') >= Date.parseExact($(params[0]).val(), 'H:mm')
      );
    },
    'Cannot be before {1}.'
  );

  $.validator.addMethod(
    'greaterThanSpec',
    function(value, element, params) {
      if (!/Invalid|NaN/.test(Date.parseExact(value, 'dd-MMM-yyyy'))) {
        return Date.parseExact(value, 'dd-MMM-yyyy') >= Date.parseExact(params[0], 'dd-MMM-yyyy');
      }
      return (
        isNaN(value) ||
        isNaN(params[0]) ||
        Date.parseExact(value, 'dd-MMM-yyyy') > Date.parseExact(params[0], 'dd-MMM-yyyy')
      );
    },
    'Cannot be before {1}.'
  );

  $.validator.addMethod(
    'partialDateValid',
    function(value, element, params) {
      if ($('#dAdverse_day').length === 0 || $('#dAdverse_year').length === 0 || $('#dAdverse_month').length === 0) {
        console.log(
          'Warning: The check_date_of_birth validation requires "#dAdverse_day, #dAdverse_year, #dAdverse_month"'
        );
        return;
      }
      var day = $('#dAdverse_day').val();
      var year = $('#dAdverse_year').val();
      var month = 'JanFebMarAprMayJunJulAugSepOctNovDec'.indexOf($('#dAdverse_month').val()) / 3;
      if (day == 'Unknown') {
        return true;
      } else {
        var mydate = new Date(year, month, day);
        var consentDate = Date.parseExact(params[0], 'dd-MMM-yyyy');
        return mydate.getTime() >= consentDate.getTime() && moment(mydate).isBefore();
      }
    },
    'Date cannot be before date of consent or in the future.'
  );

  $.validator.addMethod(
    'adverseEventResolved',
    function(value, element, params) {
      if ($('#dAdverse_day').length === 0 || $('#dAdverse_year').length === 0 || $('#dAdverse_month').length === 0) {
        console.log(
          'Warning: The check_date_of_birth validation requires "#dAdverse_day, #dAdverse_year, #dAdverse_month"'
        );
        return;
      }
      var day = $('#dAdverse_day').val();
      var year = $('#dAdverse_year').val();
      var month = 'JanFebMarAprMayJunJulAugSepOctNovDec'.indexOf($('#dAdverse_month').val()) / 3;
      if (day == 'Unknown') {
        return true;
      } else {
        var mydate = new Date(year, month, day);
        var resolutionDate = Date.parseExact(value, 'dd-MMM-yyyy');
        var consentDate = Date.parseExact(params[0], 'dd-MMM-yyyy');
        return (
          mydate.getTime() <= resolutionDate.getTime() &&
          moment(resolutionDate).isBefore() &&
          resolutionDate.getTime() >= consentDate.getTime()
        );
      }
    },
    'Date cannot be before date of adverse event or before date of consent.'
  );

  $.validator.addMethod(
    'inclusionCrit',
    function(value, element) {
      return value == 'true';
    },
    'Inclusion criteria not met'
  );

  $.validator.addMethod(
    'exclusionCrit',
    function(value, element) {
      return value == 'false';
    },
    'Exclusion criteria not met'
  );

  $.validator.addMethod(
    'salimsIdFormat',
    function(value) {
      return /^RIS-\d\d-\d\d\d\d$/i.test(value);
    },
    'Please enter a valid SaLIMS identifier (in the format RIS-xx-xxxx).'
  );
};

window.TS.addCalenderValidationTrigger = function() {
  if ($('.date-picker').length === 0) return;
  $('.date-picker').on('change', function() {
    if ($(this).length == 0) return;
    $(this).valid();
  });
};

window.TS.addSeparateDateValidationTrigger = function() {
  $('#dAdverse_month, #dAdverse_year').on('select2:close', function() {
    if ($('#dAdverse_day').length === 0) return;
    $('#dAdverse_day').valid();
  });
};

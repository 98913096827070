import * as moment from 'moment/moment';
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-plugins/sorting/datetime-moment';

window.TS.initDataTables = function(selector, data) {
  if ($(selector).length == 0) return;
  if (data === undefined) data = {};
  if (data.order === undefined) {
    if ($(selector).data('sortlist') !== undefined) {
      data.order = $(selector).data('sortlist');
    }
  }
  $.fn.dataTable.moment('DD-MMMM-YYYY');
  $.fn.dataTable.moment('DD-MMMM-YY');
  $.fn.dataTable.moment('DD-MMM-YYYY');
  $.fn.dataTable.moment('DD-MMM-YY');
  $.fn.dataTable.moment('DD-MMM-YYYY HH:mm:ss');
  $.fn.dataTable.moment('DD-MMM-YY HH:mm:ss');
  console.log('Initializing Data Table for ' + selector);
  $(selector).dataTable({
    pageLength: 25,
    dom: 'lBfrtip',
    aLengthMenu: [
      [10, 25, 50, 100, 200, -1],
      [10, 25, 50, 100, 200, 'All']
    ],
    orderClasses: false,
    columnDefs: data.columnDefs,
    order: data.order,
    retrieve: true,
    buttons: [
      data.adminVis,
      {
        extend: 'excel',
        text: 'Export to Excel',
        title: 'SPIT subject data',
        exportOptions: { orthogonal: 'filter' }
      }
    ],
    initComplete: function(settings) {
      // Add Column Searching
      this.api()
        .columns()
        .every(function() {
          var column = this;
          var filterType = $(column.header()).data('column-filtering');
          if (filterType == 'select') {
            var select = $('<select><option value="">-</option></select>')
              .appendTo($(column.footer()).empty())
              .on('change', function() {
                var val = $.fn.dataTable.util.escapeRegex($(this).val());
                column.search(val ? '^' + val + '$' : '', true, false).draw();
              });
            // Attempt to use data-filter first
            var statusItems = [];
            column
              .nodes()
              .to$()
              .each(function(d, j) {
                var thisStatus = $(j).attr('data-filter');
                if (thisStatus !== undefined) {
                  if ($.inArray(thisStatus, statusItems) === -1) statusItems.push(thisStatus);
                }
              });
            if (statusItems.length > 0) {
              // i.e. if there is a data-filter tag
              data = statusItems.sort();
              $.each(data, function(d, j) {
                if (j.trim() != '') {
                  select.append('<option value="' + j + '">' + j + '</option>');
                }
              });
            } else {
              data = column
                .data()
                .unique()
                .sort()
                .each(function(d, j) {
                  if (d.trim() != '') {
                    select.append('<option value="' + d + '">' + d + '</option>');
                  }
                });
            }
          } else if (filterType == 'text') {
            var column = this;
            var that = this;
            var title = $(this).text();
            $('<input type="text" placeholder=" Search ' + title + '" />')
              .appendTo($(column.footer()).empty())
              .on('keyup change', function() {
                if (that.search() !== this.value) {
                  that.search(this.value).draw();
                }
              });
          }
        });
    },
    preDrawCallback: function(settings) {
      // hide pagination if only one page
      var api = new $.fn.dataTable.Api(settings);
      var pagination = $(this)
        .closest('.dataTables_wrapper')
        .find('.dataTables_paginate');
      pagination.toggle(api.page.info().pages > 1);
    }
  });
};
